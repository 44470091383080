import { render, staticRenderFns } from "./OrdersTable.vue?vue&type=template&id=110669e8&scoped=true&"
import script from "./OrdersTable.vue?vue&type=script&lang=js&"
export * from "./OrdersTable.vue?vue&type=script&lang=js&"
import style0 from "./OrdersTable.vue?vue&type=style&index=0&id=110669e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110669e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardTitle,VDataTable,VIcon,VRadio,VRadioGroup,VSheet,VSpacer})
