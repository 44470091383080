<template>
  <div>
    <v-card>
      <v-card-title>
        <v-icon color="primary">mdi-order-bool-descending</v-icon>
        <span class="pl-2">Manage Orders</span>
        <v-spacer></v-spacer>
        <v-sheet elevation="3" rounded="lg" class="text-center mx-auto">
          <div class="mx-4">
            <v-radio-group v-model="orderStatus" row>
              <v-radio label="All Meals" value="all"></v-radio>
              <v-radio label="Pending" value="pending"></v-radio>
              <v-radio label="Completed" value="completed"></v-radio>
            </v-radio-group>
          </div>
        </v-sheet>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="filteredOrders"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon @click="editOrder(item)">mdi-pencil</v-icon>
          <v-icon @click="deleteOrder(item.id)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import orderService from '@/services/OrderService';

export default {
  data() {
    return {
      orderStatus: 'all',
      orders: [],
      loading: true,
      headers: [
        { text: 'Order ID', value: 'id' },
        { text: 'Date', value: 'created_at' }, 
        { text: 'Meal', value: 'meal.name' },
        { text: 'Parent', value: 'user.name' },
        { text: 'Student', value: 'student.name' },
        { text: 'School', value: 'student.student_school.name' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Total Price', value: 'total_price' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    };
  },
  computed: {
    filteredOrders() {
      const formattedOrders = this.orders.map(order => ({
        ...order,
        created_at: order.created_at.substring(0, 10) 
      }));

      if (this.orderStatus === 'all') return formattedOrders;
      const isComplete = this.orderStatus === 'completed';
      return formattedOrders.filter(order => order.status === (isComplete ? 'completed' : 'pending'));
    }
  },
  methods: {
    async fetchOrders() {
      try {
        const response = await orderService.getOrders();
        this.orders = response.data;
      } catch (err) {
        console.error('Error fetching orders:', err);
      } finally {
        this.loading = false;
      }
    },
    viewOrder(order) {
      this.$router.push({ name: 'viewOrder', params: { id: order.id } });
    },
    editOrder(order) {
      this.$router.push({ name: 'editOrder', params: { id: order.id } });
    },
    async deleteOrder(id) {
      if (confirm('Are you sure you want to delete this order?')) {
        try {
          await orderService.deleteOrder(id);
          this.fetchOrders(); // Refresh the order list
        } catch (err) {
          console.error('Error deleting order:', err);
        }
      }
    }
  },
  created() {
    this.fetchOrders();
  }
};
</script>

<style scoped>
.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.btn {
  margin-right: 5px;
}
</style>
